import * as imageConfigurationsActions from '../actions/image-configurations.actions';
import { ImageConfigurationsList, ImageConfigurationsManage, ImageConfigurationsOptions } from '../models';

export interface State {
    list: ImageConfigurationsList;
    manage: ImageConfigurationsManage;
    options: ImageConfigurationsOptions;
}

const initialState: State = {
    list: {
        data: {
            results: [],
            next: null,
            previous: null,
            count: null,
        },
        errors: {},
        isFetching: true,
    },
    manage: {
        data: {
            id: null,
            backgroundColor: '',
            backgroundOpacity: null,
            backgroundType: null,
            cropPadding: null,
            cropPaddingUnit: null,
            dimensionUnit: null,
            dpi: null,
            fileFormat: '',
            height: null,
            isCustom: false,
            name: '',
            usesCropping: false,
            width: null,
        },
        errors: {},
        isSaving: false,
    },
    options: {
        data: {},
        isFetching: true,
    },
};

export function reducer(state: State = initialState, action: imageConfigurationsActions.Actions): State {
    switch (action.type) {
        case imageConfigurationsActions.ActionTypes.GET_IMAGE_CONFIGURATIONS_REQUEST: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: {},
                    isFetching: true,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.GET_IMAGE_CONFIGURATIONS_SUCCESS: {
            return {
                ...state,
                list: {
                    ...state.list,
                    data: action.payload,
                    isFetching: false,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.GET_IMAGE_CONFIGURATIONS_ERROR: {
            return {
                ...state,
                list: {
                    ...state.list,
                    errors: action.payload,
                    isFetching: false,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.CREATE_IMAGE_CONFIGURATION_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.CREATE_IMAGE_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: action.payload,
                    isSaving: false,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.CREATE_IMAGE_CONFIGURATION_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.DELETE_IMAGE_CONFIGURATION_REQUEST: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: {},
                    isSaving: true,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.DELETE_IMAGE_CONFIGURATION_SUCCESS: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    data: initialState.manage.data,
                    isSaving: false,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.DELETE_IMAGE_CONFIGURATION_ERROR: {
            return {
                ...state,
                manage: {
                    ...state.manage,
                    errors: action.payload,
                    isSaving: false,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.GET_OPTIONS_REQUEST: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: true,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.GET_OPTIONS_SUCCESS: {
            return {
                ...state,
                options: {
                    ...state.options,
                    isFetching: false,
                    data: action.payload,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.CLEAR_LIST: {
            return {
                ...state,
                list: {
                    ...initialState.list,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.CLEAR_OPTIONS: {
            return {
                ...state,
                options: {
                    ...initialState.options,
                },
            };
        }

        case imageConfigurationsActions.ActionTypes.CLEAR_MANAGE: {
            return {
                ...state,
                manage: {
                    ...initialState.manage,
                },
            };
        }

        default:
            return state;
    }
}

export const getList = (state: State) => state.list;
export const getListData = (state: State) => state.list.data;
export const getListErrors = (state: State) => state.list.errors;
export const getListIsFetching = (state: State) => state.list.isFetching;

export const getManage = (state: State) => state.manage;
export const getManageData = (state: State) => state.manage.data;
export const getManageErrors = (state: State) => state.manage.errors;
export const getManageIsSaving = (state: State) => state.manage.isSaving;

export const getOptions = (state: State) => state.options;
export const getOptionsData = (state: State) => state.options.data;
export const getOptionsIsFetching = (state: State) => state.options.isFetching;

import { type } from '@app/utils';
import { Action } from '@ngrx/store';

const ACTION_PREFIX = '[Image Configurations]';

export const ActionTypes = {
    GET_IMAGE_CONFIGURATIONS_REQUEST: type(`${ACTION_PREFIX} Get Image Configurations Request`),
    GET_IMAGE_CONFIGURATIONS_SUCCESS: type(`${ACTION_PREFIX} Get Image Configurations Success`),
    GET_IMAGE_CONFIGURATIONS_ERROR: type(`${ACTION_PREFIX} Get Image Configurations Error`),
    CREATE_IMAGE_CONFIGURATION_REQUEST: type(`${ACTION_PREFIX} Create Image Configuration Request`),
    CREATE_IMAGE_CONFIGURATION_SUCCESS: type(`${ACTION_PREFIX} Create Image Configuration Success`),
    CREATE_IMAGE_CONFIGURATION_ERROR: type(`${ACTION_PREFIX} Create Image Configuration Error`),
    DELETE_IMAGE_CONFIGURATION_REQUEST: type(`${ACTION_PREFIX} Delete Image Configuration Request`),
    DELETE_IMAGE_CONFIGURATION_SUCCESS: type(`${ACTION_PREFIX} Delete Image Configuration Success`),
    DELETE_IMAGE_CONFIGURATION_ERROR: type(`${ACTION_PREFIX} Delete Image Configuration Error`),
    GET_OPTIONS_REQUEST: type(`${ACTION_PREFIX} Get Options Request`),
    GET_OPTIONS_SUCCESS: type(`${ACTION_PREFIX} Get Options Success`),
    CLEAR_LIST: type(`${ACTION_PREFIX} Clear List`),
    CLEAR_MANAGE: type(`${ACTION_PREFIX} Clear Manage`),
    CLEAR_OPTIONS: type(`${ACTION_PREFIX} Clear Options`),
};

/**
 * Create 'Get Image Configurations Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class GetImageConfigurationsRequestAction implements Action {
    type = ActionTypes.GET_IMAGE_CONFIGURATIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Image Configurations Succes' Action
 * @param payload Contains object with image configurations list and params
 */
export class GetImageConfigurationsSuccessAction implements Action {
    type = ActionTypes.GET_IMAGE_CONFIGURATIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Image Configurations Error' Action
 * @param payload Contains object with errors
 */
export class GetImageConfigurationsErrorAction implements Action {
    type = ActionTypes.GET_IMAGE_CONFIGURATIONS_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Image Configuration Request' Action
 * @param payload Contains object with image configuration data
 */
export class CreateImageConfigurationRequestAction implements Action {
    type = ActionTypes.CREATE_IMAGE_CONFIGURATION_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Image Configuration Succes' Action
 * @param payload Contains object with image configurations list and params
 */
export class CreateImageConfigurationSuccessAction implements Action {
    type = ActionTypes.CREATE_IMAGE_CONFIGURATION_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Create Image Configurations Error' Action
 * @param payload Contains object with errors
 */
export class CreateImageConfigurationErrorAction implements Action {
    type = ActionTypes.CREATE_IMAGE_CONFIGURATION_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Image Configuration Request' Action
 * @param payload Contains image configuration id
 */
export class DeleteImageConfigurationRequestAction implements Action {
    type = ActionTypes.DELETE_IMAGE_CONFIGURATION_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Image Configuration Succes' Action
 */
export class DeleteImageConfigurationSuccessAction implements Action {
    type = ActionTypes.DELETE_IMAGE_CONFIGURATION_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Delete Image Configuration Error' Action
 * @param payload Contains object with errors
 */
export class DeleteImageConfigurationErrorAction implements Action {
    type = ActionTypes.DELETE_IMAGE_CONFIGURATION_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Request' Action
 */
export class GetOptionsRequestAction implements Action {
    type = ActionTypes.GET_OPTIONS_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Get Options Success' Action
 */
export class GetOptionsSuccessAction implements Action {
    type = ActionTypes.GET_OPTIONS_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear List' Action
 */
export class ClearListAction implements Action {
    type = ActionTypes.CLEAR_LIST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Manage' Action
 */
export class ClearManageAction implements Action {
    type = ActionTypes.CLEAR_MANAGE;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Options' Action
 */
export class ClearOptionsAction implements Action {
    type = ActionTypes.CLEAR_OPTIONS;

    constructor(public payload?: any) {}
}

export type Actions =
    | GetImageConfigurationsRequestAction
    | GetImageConfigurationsSuccessAction
    | GetImageConfigurationsErrorAction
    | CreateImageConfigurationRequestAction
    | CreateImageConfigurationSuccessAction
    | CreateImageConfigurationErrorAction
    | DeleteImageConfigurationRequestAction
    | DeleteImageConfigurationSuccessAction
    | DeleteImageConfigurationErrorAction
    | GetOptionsRequestAction
    | GetOptionsSuccessAction
    | ClearListAction
    | ClearOptionsAction
    | ClearManageAction;

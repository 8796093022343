import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromImageConfigurations from '../reducers/image-configurations.reducer';

@Injectable()
export class ImageConfigurationsSelectors {
    /**
     * Get state.imageConfigurations
     */
    getImageConfigurationsState() {
        return ($state: State): any => $state.imageConfigurations;
    }

    /**
     * Get list from state.imageConfigurations
     */
    getList() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getList,
        );
    }

    /**
     * Get list.data from state.imageConfigurations
     */
    getListData() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getListData,
        );
    }

    /**
     *  Get list.errors from state.imageConfigurations
     */
    getListErrors() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getListErrors,
        );
    }

    /**
     * Get list.isFetching from state.imageConfigurations
     */
    getListIsFetching() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getListIsFetching,
        );
    }

    /**
     * Get manage from state.imageConfigurations
     */
    getManage() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getManage,
        );
    }

    /**
     * Get manage.data from state.imageConfigurations
     */
    getManageData() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getManageData,
        );
    }

    /**
     *  Get manage.errors from state.imageConfigurations
     */
    getManageErrors() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getManageErrors,
        );
    }

    /**
     * Get manage.isSaving from state.imageConfigurations
     */
    getManageIsSaving() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getManageIsSaving,
        );
    }

    /**
     * Get options from state.imageConfigurations
     */
    getOptions() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getOptions,
        );
    }

    /**
     * Get options.data from state.imageConfigurations
     */
    getOptionsData() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getOptionsData,
        );
    }

    /**
     * Get options.isFetching from state.imageConfigurations
     */
    getOptionsIsFetching() {
        return createSelector(
            this.getImageConfigurationsState(),
            fromImageConfigurations.getOptionsIsFetching,
        );
    }
}
